import icon from "../images/icons/special.svg";

const DEFAULT_DATA = {
  icon: icon,
  title_line: "на особых условиях",
  subtitle: "ТОЛЬКО 3 ДНЯ",
  btn_text: "Подробности в отделе продаж",
};

export default [
  {
    ...DEFAULT_DATA,
    model_name: "HYUNDAI TUCSON",
    slider: Array.from(Array(55)).map((_, i) =>
      require(`../images/slides/tucson/${i + 1}.jpg`)
    ),
  },
  {
    ...DEFAULT_DATA,
    model_name: "HYUNDAI PALISADE",
    slider: Array.from(Array(30)).map((_, i) =>
      require(`../images/slides/palisade/${i + 1}.jpg`)
    ),
  },
  {
    ...DEFAULT_DATA,
    model_name: "HYUNDAI SANTA FE",
    slider: Array.from(Array(7)).map((_, i) =>
      require(`../images/slides/santafe/${i + 1}.jpg`)
    ),
  },
  {
    ...DEFAULT_DATA,
    model_name: "HYUNDAI STARIA",
    slider: Array.from(Array(22)).map((_, i) =>
      require(`../images/slides/staria/${i + 1}.jpg`)
    ),
  },
]
