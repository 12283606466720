// предыдущую версию комплектаций можно найти в коммите 6e53ab73

export default [
  /*{
    name: 'Palisade',
    compl: 'Family',
    engine: '2,2 дизель, 8 АТ, 4WD, 200 лс',
    price: '6 250 000 ₽',
    options: [
      'Apple car play /Android auto',
      'Электрорегулировка сиденья водителя и пассажира. Память сидений',      
      "Аудиосистема с дисплеем 12,3'' (Radio + RDS Аудиосистема премиум-класса HARMAN KARDON: 12 динамиков, включая сабвуфер, внешний усилитель",
      'Легкосплавные диски 20',
      'Камера 360',
      'Электропривод двери багажника'
    ]
  },
  {
    name: 'Palisade',
    compl: 'Family',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '5 820 000 ₽',
    options: [
      'Apple car play /Android auto',
      'Электрорегулировка сиденья водителя и пассажира. Память сидений',      
      "Аудиосистема с дисплеем 12,3'' (Radio + RDS Аудиосистема премиум-класса HARMAN KARDON: 12 динамиков, включая сабвуфер, внешний усилитель",
      'Легкосплавные диски 20',
      'Камера 360',
      'Электропривод двери багажника'
    ]
  },*/
  {
    name: 'Palisade',
    compl: 'Luxe',
    engine: '2,2 дизель, 8 АТ, 4WD, 200 лс',
    price: '6 560 000 ₽',
    options: [
      'Apple car play /Android auto',
      'Двухсекционный люк с электроприводом',      
      'Проекция показаний приборов на лобовое стекло',
      'Раздельные сиденья 2-го ряда, Вентиляция сидений 2-го ряда',
      'Умный круиз-контроль SCC, Система автоматического торможения при движении задним ходом (PCA-R), Интеллектуальный ассистент дистанционной парковки (RSPA), Ассистент удержания автомобиля в полосе движения LKA, Ассистент предотвращения столкновений спереди FCA, Ассистент предотвращения столкновений при повороте налево FCA-JX, Асси- стент следования полосы движения LFA'
    ]
  },
  {
    name: 'Palisade',
    compl: 'Luxe',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '7 150 000 ₽',
    options: [
      'Apple car play /Android auto',
      'Двухсекционный люк с электроприводом',      
      'Проекция показаний приборов на лобовое стекло',
      'Раздельные сиденья 2-го ряда, Вентиляция сидений 2-го ряда',
      'Умный круиз-контроль SCC, Система автоматического торможения при движении задним ходом (PCA-R), Интеллектуальный ассистент дистанционной парковки (RSPA), Ассистент удержания автомобиля в полосе движения LKA, Ассистент предотвращения столкновений спереди FCA, Ассистент предотвращения столкновений при повороте налево FCA-JX, Асси- стент следования полосы движения LFA'
    ]
  },
  {
    name: 'Palisade',
    compl: 'Calligraphy',
    engine: '2,2 дизель, 8 АТ, 4WD, 200 лс',
    price: '7 080 000 ₽',
    options: [
      'Apple car play /Android auto',
      'Отделка сидений кожей Nappa',
      'Отделка потолка и боковых стоек замшей',
      'Диски оригинального дизайна R20 (Многоспицевые)',
      'Оттоманка для сидений водителя и пассажира',
      'Оригинальные элементы салона'
    ]
  },
  {
    name: 'Palisade',
    compl: 'Calligraphy',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '7 730 000 ₽',
    options: [
      'Apple car play /Android auto',
      'Отделка сидений кожей Nappa',
      'Отделка потолка и боковых стоек замшей',
      'Диски оригинального дизайна R20 (Многоспицевые)',
      'Оттоманка для сидений водителя и пассажира',
      'Оригинальные элементы салона'
    ]
  },
  /*{
    name: 'Palisade',
    compl: 'Black Edition',
    engine: '2,2 дизель, 8 АТ, 4WD, 200 лс',
    price: '7 090 000 ₽',
    options: [
      'Apple car play /Android auto',     
      'Версия Calligraphy с черным стайлингом',
      'Черные молдинги дверей',
      'Черная решетка радиатора',
      'Черные колесные диски многоспицевого дизайна',
      'Черный потолок натуральная замша'
    ]
  },
  {
    name: 'Palisade',
    compl: 'Black Edition',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '7 190 000 ₽',
    options: [
      'Apple car play /Android auto',     
      'Версия Calligraphy с черным стайлингом',
      'Черные молдинги дверей',
      'Черная решетка радиатора',
      'Черные колесные диски многоспицевого дизайна',
      'Черный потолок натуральная замша'
    ]
  },*/
  {
    name: 'Mufasa',
    compl: 'DLX',
    engine: '2.0 Бензин, 6 АТ, 2WD, 160л.с',
    price: '2 650 000 ₽',
    options: [
      'Кондиционер',     
      'Камера заднего вида',
      'Подогрев передних сидений',
      'Встроенный 12,3-дюймовый интеллектуальный двойной экран Vision',
      'Электронный ручной тормоз (с автоматической парковкой)',
      'Задняя подставка для планшета',
      'Электрическая 6-позиционная регулировка сиденья водителя',
      'Отделка сидений кожей',
      '6 подушек безопасности',
      'Светодиодные фары ближнего и дальнего света',
      'Интеллектуальный круиз контроль с ограничителем скорости'
    ]
  },
  {
    name: 'Mufasa',
    compl: 'LUX',
    engine: '2.0 Бензин, 6 АТ, 2WD, 160л.с',
    price: '2 890 000 ₽',
    options: [
      'Панорамная крыша с люком',     
      'Климат контроль',
      'Ионизатор воздуха',
      'Подогрев зеркал заднего вида',
      'Автоматический дальний свет'
    ]
  },
  {
    name: 'Tucson',
    compl: 'Comfort',
    engine: '2,0 бензин, 6 АТ, 2WD, 149 лс',
    price: '3 250 000 ₽',
    options: [
     ' Лекгосплавные диски 17"" с шинами 235/65R17',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Аудиосистема с дисплеем 8""  Apple Carplay / Android auto + Камера заднего вида',
      'Камера заднего вида, задние парктроники',
      'Безключевой доступ в салон и запуск с кнопки',
    ]
  },
  {
    name: 'Tucson',
    compl: 'Comfort',
    engine: '2,0 бензин, 6 АТ, 4WD, 149 лс',
    price: '3 350 000 ₽',
    options: [
     ' Лекгосплавные диски 17"" с шинами 235/65R17',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Аудиосистема с дисплеем 8""  Apple Carplay / Android auto + Камера заднего вида',
      'Камера заднего вида, задние парктроники',
      'Безключевой доступ в салон и запуск с кнопки',
    ]
  },
  {
    name: 'Tucson',
    compl: 'Travel',
    engine: '2,0 бензин, 6 АТ, 2WD, 149 лс',
    price: '3 350 000 ₽',
    options: [
      'Кожанный салон, натуральная кожа',
      'Электропривод сиденья',
      'Электронный стояночный тормоз EPB + AutoHold',
      'Светодиодные LED фары проекционного типа',
      'Парктроник передний и задний',
      'Лекгосплавные диски 18"" с шинами 235/60R18',
    ]
  },
  {
    name: 'Tucson',
    compl: 'Travel',
    engine: '2,0 бензин, 6 АТ, 4WD, 149 лс',
    price: '3 490 000 ₽',
    options: [
      'Кожанный салон, натуральная кожа',
      'Электропривод сиденья',
      'Электронный стояночный тормоз EPB + AutoHold',
      'Светодиодные LED фары проекционного типа',
      'Парктроник передний и задний',
      'Лекгосплавные диски 18"" с шинами 235/60R18',
    ]
  },
  {
    name: 'Tucson',
    compl: 'Travel',
    engine: '2,5 бензин, 6 АТ, 4WD, 149 лс',
    price: '4 050 000  ₽',
    options: [
      'Кожанный салон, натуральная кожа',
      'Электропривод сиденья',
      'Электронный стояночный тормоз EPB + AutoHold',
      'Светодиодные LED фары проекционного типа',
      'Парктроник передний и задний',
      'Лекгосплавные диски 18"" с шинами 235/60R18',
    ]
  },
  /*{
    name: 'Tucson',
    compl: 'Travel',
    engine: '2,0 дизель, 8 АТ, 4WD, 186 лс',
    price: '3 990 000  ₽',
    options: [
      'Кожанный салон, натуральная кожа',
      'Электропривод сиденья',
      'Электронный стояночный тормоз EPB + AutoHold',
      'Светодиодные LED фары проекционного типа',
      'Парктроник передний и задний',
      'Лекгосплавные диски 18" с шинами 235/60R18',
    ]
  },*/
  {
    name: 'Tucson',
    compl: 'High-tech',
    engine: '2,0 бензин, 6 АТ, 4WD, 149 лс',
    price: '3 890 000  ₽',
    options: [
      'Цифровая приборная панель',
      "Навигационная ситема с экраном 10.25'', аудиосистема премиум класса",
      'Премиальная аудиосистема KRELL',
      'Память для сиденья водителя',
      'Вентиляция передних сидений',
      'Легкосплавные диски 19',
    ]
  },
  {
    name: 'Tucson',
    compl: 'High-tech',
    engine: '2,5 бензин, 6 АТ, 4WD, 149 лс',
    price: '4 250 000  ₽',
    options: [
      'Цифровая приборная панель',
      "Навигационная ситема с экраном 10.25'', аудиосистема премиум класса",
      'Премиальная аудиосистема KRELL',
      'Память для сиденья водителя',
      'Вентиляция передних сидений',
      'Легкосплавные диски 19',
    ]
  },
  {
    name: 'Tucson',
    compl: 'Luxe',
    engine: '2,5 бензин, 6 АТ, 4WD, 149 лс',
    price: '4 390 000  ₽',
    options: [
      'Панорамная крыша с люком',
      'Система кругового обзора SVM',
      'Система обзора слепых зон BVM',
      'Светодиодное LED освещение салона',
    ]
  },
  {
    name: 'Santa Fe',
    compl: 'Travel',
    engine: '2,5 бензин, 6 АТ, 4WD, 180 лс',
    price: '4 700 000  ₽',
    options: [
      'Apple car play /Android auto',
      'Кожанный салон, натуральная кожа',
      'Электропривод сидений водителя и пассажира',
      'Светодиодные LED фары проекционного типа',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Система бесключевого доступа Smart Key и кнопка запуска двигателя',
      'Камера заднего вида + парктроники передние и задние',
      'Лекгосплавные диски 18" с шинами 235/60R18',
    ]
  },
  {
    name: 'Santa Fe',
    compl: 'Prime',
    engine: '2,5 бензин, 6 АТ, 4WD, 180 лс',
    price: '5 250 000  ₽',
    options: [
      'Панель приборов SuperVision с LCD монитором 12,3“ + Rheostat',
      'Боковые механические шторки для пассажиров заднего ряда',
      'Вентиляция передних сидений',
      'Электропривод двери багажника',
      'Лекгосплавные диски 19“ с шинами 235/55R19',
      'Задние амортизаторы с функцией самовыравнивания',
    ]
  },
  /*{
    name: 'Santa Fe',
    compl: 'Prime',
    engine: '2,2 дизель, 8 DCT, 4WD, 199 лс',
    price: '4 990 000  ₽',
    options: [
      'Панель приборов SuperVision с LCD монитором 12,3“ + Rheostat',
      'Боковые механические шторки для пассажиров заднего ряда',
      'Вентиляция передних сидений',
      'Электропривод двери багажника',
      'Лекгосплавные диски 19“ с шинами 235/55R19',
      'Задние амортизаторы с функцией самовыравнивания',
    ]
  },*/
  {
    name: 'Santa Fe',
    compl: 'High-tech',
    engine: '2,5 бензин, 6 АТ, 4WD, 180 лс',
    price: '5 590 000  ₽',
    options: [
      'Панорамная крыша с люком',
      'Память сиденья водителя, Электрорегулировка подколенного и поясничного  подпоров сиденья водителя',
      'Лекгосплавные диски 20“ с шинами 255/45R20',
      '360 Система камер кругового обзора, слепых зон',
      'Аудиосистема премиум Harman/Kardon,Apple car play /Android auto',
      'Легкосплавные диски 19"',
    ]
  },
  {
    name: 'Santa Fe',
    compl: 'High-tech',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '5 990 000  ₽',
    options: [
      'Панорамная крыша с люком',
      'Память сиденья водителя, Электрорегулировка подколенного и поясничного  подпоров сиденья водителя',
      'Лекгосплавные диски 20“ с шинами 255/45R20',
      '360 Система камер кругового обзора, слепых зон',
      'Аудиосистема премиум Harman/Kardon,Apple car play /Android auto',
      'Легкосплавные диски 19"',
    ]
  },
  /*{
    name: 'Santa Fe',
    compl: 'High-tech',
    engine: '2,2 дизель, 8 DCT, 4WD, 199 лс',
    price: '5 950 000  ₽',
    options: [
      'Панорамная крыша с люком',
      'Память сиденья водителя, Электрорегулировка подколенного и поясничного  подпоров сиденья водителя',
      'Лекгосплавные диски 20“ с шинами 255/45R20',
      '360 Система камер кругового обзора, слепых зон',
      'Аудиосистема премиум Harman/Kardon,Apple car play /Android auto',
      'Легкосплавные диски 19"',
    ]
  },
  {
    name: 'Santa Fe',
    compl: 'Luxe',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '6 150 000  ₽',
    options: [
      'Дополнение к версии High-tech',
      'Проекция показаний приборов на лобовое стекло',
      'Умный круиз-контроль SCC',
      'Ассистент предотвращения столкновений спереди FCA',
      'Ассистент предотвращения столкновений при повороте налево FCA-JX',   
    ]
  },
  {
    name: 'Santa Fe',
    compl: 'Luxe',
    engine: '2,2 дизель, 8 DCT, 4WD, 199 лс',
    price: '6 150 000  ₽',
    options: [
      'Дополнение к версии High-tech',
      'Проекция показаний приборов на лобовое стекло',
      'Умный круиз-контроль SCC',
      'Ассистент предотвращения столкновений спереди FCA',
      'Ассистент предотвращения столкновений при повороте налево FCA-JX',   
    ]
  },
  {
    name: 'Santa Fe',
    compl: 'Luxe',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '6 150 000  ₽',
    options: [
      'Дополнение к версии High-tech',
      'Проекция показаний приборов на лобовое стекло',
      'Умный круиз-контроль SCC',
      'Ассистент предотвращения столкновений спереди FCA',
      'Ассистент предотвращения столкновений при повороте налево FCA-JX',   
    ]
  },
  {
    name: 'Santa Fe',
    compl: 'Luxe',
    engine: '2,2 дизель, 8 DCT, 4WD, 199 лс',
    price: '6 150 000  ₽',
    options: [
      'Дополнение к версии High-tech',
      'Проекция показаний приборов на лобовое стекло',
      'Умный круиз-контроль SCC',
      'Ассистент предотвращения столкновений спереди FCA',
      'Ассистент предотвращения столкновений при повороте налево FCA-JX',   
    ]
  },*/
  /*{
    name: 'Staria',
    compl: 'Prestige',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '5 950 000  ₽',
    options: [
      'Apple car play /Android auto',
      'Кожанный салон, натуральная кожа',
      'Электропривод сидений водителя и пассажира',
      'Светодиодные LED фары проекционного типа',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Система бесключевого доступа Smart Key и кнопка запуска двигателя',
      'Камера заднего вида + парктроники передние и задние',
      'Лекгосплавные диски 18" с шинами 235/60R18',
    ]
  },*/
  {
    name: 'Staria',
    compl: 'Prestige',
    engine: '2,2 дизель, 8 АТ, 4WD, 177 лс',
    price: '6 950 000  ₽',
    options: [
      'Apple car play /Android auto',
      'Кожанный салон, натуральная кожа',
      'Электропривод сидений водителя и пассажира',
      'Светодиодные LED фары проекционного типа',
      'Подогрев руля, форсунок, зоны покоя дворников',
      'Система бесключевого доступа Smart Key и кнопка запуска двигателя',
      'Камера заднего вида + парктроники передние и задние',
      'Лекгосплавные диски 18" с шинами 235/60R18',
    ]
  },
  {
    name: 'Staria',
    compl: 'Premium',
    engine: '3,5 бензин, 8 АТ, 4WD, 249 лс',
    price: '6 950 000  ₽',
    options: [
      'Раздельные VIP-сиденья 2-го ряда с оттоманками и функцией релаксации',
      'Вентиляция всех  сидений',
      'Отделка сидений кожей Nappa, потолка и стоек замшей',
      'Цифровая панель приборов с цветным экраном 10.25',
      'Навигационная система4 с сенсорным экраном 10.25',
      'Адаптивный круиз-контроль (SCC) с ассистентом движения в пробке (LVDA)',
      'Отделка сидений кожей Nappa, потолка и стоек замшей',
      'Остекление с дополнительной шумоизоляцией (лобовое и передние боковые стекла)',
      'Аудиосистема премиум-класса Bose: 12 динамиков, вкл. сабвуфер и внешний усилитель',
      'На выбор 4 цвета интерьера, включая 2 уникальных: светло-серый и коричневый',
    ]
  },
  {
    name: 'Staria',
    compl: 'Premium',
    engine: '2,2 дизель, 8 АТ, 4WD, 177 лс',
    price: '7 350 000  ₽',
    options: [
      'Раздельные VIP-сиденья 2-го ряда с оттоманками и функцией релаксации',
      'Вентиляция всех  сидений',
      'Отделка сидений кожей Nappa, потолка и стоек замшей',
      'Цифровая панель приборов с цветным экраном 10.25',
      'Навигационная система4 с сенсорным экраном 10.25',
      'Адаптивный круиз-контроль (SCC) с ассистентом движения в пробке (LVDA)',
      'Отделка сидений кожей Nappa, потолка и стоек замшей',
      'Остекление с дополнительной шумоизоляцией (лобовое и передние боковые стекла)',
      'Аудиосистема премиум-класса Bose: 12 динамиков, вкл. сабвуфер и внешний усилитель',
      'На выбор 4 цвета интерьера, включая 2 уникальных: светло-серый и коричневый',
    ]
  },
  {
    name: 'Sonata',
    compl: 'Comfort',
    engine: '2,0 бензин, 6 АТ, 150 лс',
    price: '3 100 000  ₽',
    options: [
      'Двухзонный климат-контроль',
      'Подогрев руля, передних и задних сидений, ,лобового стекла и зеркал',
      'Комбинация кожа / ткань в отделке сидений',
      'Светодиодные фары ближнего и дальнего света',
      'Легкосплавные диски R17"',
    ]
  },
  /*{
    name: 'Sonata',
    compl: 'Comfort',
    engine: '2,5 бензин, 6 АТ, 180 лс',
    price: '3 520 000  ₽',
    options: [
      'Двухзонный климат-контроль',
      'Подогрев руля, передних и задних сидений, ,лобового стекла и зеркал',
      'Комбинация кожа / ткань в отделке сидений',
      'Светодиодные фары ближнего и дальнего света',
      'Легкосплавные диски R17',
    ]
  },*/
  {
    name: 'Sonata',
    compl: 'Style',
    engine: '2,0 бензин, 6 АТ, 150 лс',
    price: '3 450 000  ₽',
    options: [
      'Полностью цифровая приборная панель 12,3',
      'Телематические сервисы Bluelink6: дистанционный запуск двигателя, настройка температуры в салоне, управление опциями подогрева и др.',
      'Кожа в отделке сидений, электрорегулировки сиденья водителя в 10 направлениях',
      'Контурная подсветка интерьера',
      'Премиальная аудиосистема Bose (11 динамиков + сабвуфер)',
    ]
  },
  /*{
    name: 'Sonata',
    compl: 'Style',
    engine: '2,5 бензин, 6 АТ, 180 лс',
    price: '3 660 000  ₽',
    options: [
      'Полностью цифровая приборная панель 12,3',
      'Телематические сервисы Bluelink6: дистанционный запуск двигателя, настройка температуры в салоне, управление опциями подогрева и др.',
      'Кожа в отделке сидений, электрорегулировки сиденья водителя в 10 направлениях',
      'Контурная подсветка интерьера',
      'Премиальная аудиосистема Bose (11 динамиков + сабвуфер)',
    ]
  },*/
  {
    name: 'Sonata',
    compl: 'Business',
    engine: '2,5 бензин, 6 АТ, 180 лс',
    price: '3 790 000  ₽',
    options: [
      'Светодиодные фары проекционного типа',
      'Система кругового обзора',
      'Вентиляция передних сидений',
      'Система обзора слепых зон',
      'Легкосплавные диски 18',
    ]
  },
  /*{
    name: 'Sonata',
    compl: 'Prestige+PANO',
    engine: '2,5 бензин, 6 АТ, 180 лс',
    price: '3 800 000  ₽',
    options: [
      'панорамная крыша с люком',
      'проекционный дисплей 8',
      'шторка заднего стекла с электроприводом',
      'адаптивный круиз-контроль (SCC) с ассистентом',
      'движения в пробке (LVDA), система автоматического управления дальним светом (HBA), система предотвращения фронтального столкновения при повороте налево на перекрестке (FCA-JT)',
    ]
  },
  {
    name: 'Elantra NEW',
    compl: 'Luxe',
    engine: '1,5  CVT , 115 лc',
    price: '1 870 000  ₽',
    options: [
      'Панорамный люк в крыше',
      'Легкосплавные диски 17',
      'Кожанная отделка салона',
      'Светодиодные фары ближнего и дальнего света',
      'Мультимедийная система с цветным 12" экраном',
      'Камера заднего вида, Передний и задний парктроник',
    ]
  },
  {
    name: 'Elantra NEW',
    compl: 'Exclusive Edition',
    engine: '1,5  CVT , 115 лc',
    price: '1 990 000  ₽',
    options: [
      'В дополнение к версии Luxe',
      'Двухзонный климат контроль',
      'Подогрев руля и сидений',
    ]
  },
  {
    name: 'Elantra NEW',
    compl: 'Elegance',
    engine: '1,6 бензин, 6 АТ, 128 лс',
    price: '2 150 000  ₽',
    options: [
      'Навигационная система, премиальная аудиосистема Bose',
      'Кожа в отделке сидений',
      'Система контроля слепых зон',
      'Электропривод и память настроек сиденья водителя',
      'Светодиодная подсветка передней панели',
    ]
  },
  {
    name: 'Elantra NEW',
    compl: 'Prestige',
    engine: '1,6 бензин, 6 АТ, 128 лс',
    price: '2 250 000  ₽',
    options: [
      '1,6 бензин, 6 АТ, 128 лс',
      'Электрорегулировки сиденья переднего пассажира',
      'Уникальный цвет салона',
      'Легкосплавные диски 17',
      'Память настроек сиденья водителя (для двух водителей: сиденье, внешние зеркала)',
    ]
  },
  {
    name: 'Elantra NEW',
    compl: 'Active',
    engine: '1,6 бензин, 6 АТ, 128 лс',
    price: '2 350 000  ₽',
    options: [
      'Двухзонный климат-контроль с функцией антизапотевания лобового стекла',
      'Светодиодные фары ближнего и дальнего света проекционного типа',
      'Задние датчики парковки',
      'Круиз-контроль с управлением на руле',
      'Легкосплавные диски 16"" с шинами 205/55 R16',
    ]
  },*/
]